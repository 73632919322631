import './index.css';
import { FormControl, InputLabel, Input, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { MuiChipsInput } from 'mui-chips-input'
import { useEffect, useState } from 'react';

export const Letter = ({ selectedTemplate, setLetterDetails, letterDetails }) => {
  const { id } = selectedTemplate;
  const [chips, setChips] = useState([]);
  const letterPosition = id === 1 ? 'flex-end' : id === 2 ? 'flex-start' : 'center';


  const handleWishlistInput = (newChips) => {
    setChips(newChips);
  }

  useEffect(() => {
    setLetterDetails({
      ...letterDetails,
      wishlist: chips.join(',')
    });
  }, [chips]);

  const handleChange = e => {
    const { name, value } = e.target;
    setLetterDetails({
      ...letterDetails,
      [name]: value,
    });
  };

  const handleGratefulFor = e => {
    setLetterDetails({
      ...letterDetails,
      greatful: e.currentTarget.textContent,
    });
  };

  const handleToDo = e => {
    setLetterDetails({
      ...letterDetails,
      willDo: e.currentTarget.textContent,
    });
  };

  const handleDid = e => {
    setLetterDetails({
      ...letterDetails,
      did: e.currentTarget.textContent,
    });
  };

  return (
    <div className="letter" style={{ backgroundImage: `url(${selectedTemplate.src})` }}>
      <div style={{ display: 'flex', justifyContent: letterPosition }}>
        <div className="form">
          <div className="write-letter-page">
            <div className="first">
              My name
              <FormControl className="letter-input" variant="standard" sx={{ width: '280px', margin: '0 1rem' }} mandatory>
                <InputLabel id="name"></InputLabel>
                <Input
                  labelId="name"
                  style={{ marginTop: '0' }}
                  id="name"
                  name="name"
                  variant="standard"
                  onChange={handleChange}
                />
              </FormControl>
              and I am
              <FormControl className="letter-input" variant="standard" sx={{ width: '40px', margin: '0 1rem' }}>
                <InputLabel id="age"></InputLabel>
                <Input
                  labelId="age"
                  id="age"
                  type="number"
                  name="age"
                  style={{ marginTop: '0' }}
                  variant="standard"
                  onChange={handleChange}
                />
              </FormControl>
              years old.
            </div>
            <div className="second">
              This year I have been
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="haveBeen"
                      onChange={handleChange}
                      value="good"
                      checked={letterDetails.haveBeen === 'good'}
                    />
                  }
                  label="Good"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="haveBeen"
                      onChange={handleChange}
                      value="veryGood"
                      checked={letterDetails.haveBeen === 'veryGood'}
                    />
                  }
                  label="Very Good"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="haveBeen"
                      onChange={handleChange}
                      value="naughty"
                      checked={letterDetails.haveBeen === 'naughty'}
                    />
                  }
                  label="Naughty"
                />
              </FormGroup>
            </div>

            <div className="third">
              <span>Some of the nice things I did were:</span>
              <div className="text" contentEditable onInput={handleDid}></div>
            </div>
            <div className="third">
              <span>Nice things I’d like to do next year:</span>
              <div className="text" contentEditable onInput={handleToDo}></div>
            </div>
            <div className="third">
              Things I am grateful for:
              <div className="text" contentEditable onInput={handleGratefulFor}></div>
            </div>
            <div className="third">
              My Christmas wishlist:
              <div>
                <MuiChipsInput
                  className='chips-input'
                  value={chips}
                  onChange={handleWishlistInput}
                  placeholder="Type a specific toy name and press enter to add to the list"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
