import Particles from 'react-particles';
import { loadFull } from 'tsparticles';
import { useCallback } from 'react';
import { FaCopy } from 'react-icons/fa'; // Import the clipboard copy icon
import './ParentSuccess.css';

import check from '../../../../../../images/check.png';
import KidsSanta from '../../../../../../images/parents-santa.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const totalDuration = 10,
  emittersLifeCount = totalDuration / 0.5;

const configs = {
  // Particle configurations (unchanged)
};

export const ParentSuccess = ({ link }) => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    console.log({ container });
  }, []);

  return (
    <>
      <Particles id="tsparticles" options={configs} init={particlesInit} loaded={particlesLoaded} />
      <div className="kids-success">
        <img src={check} alt="" />
        <p>
          Congratulations, <br />
          you've shared your registry list successfully!
        </p>
        {link && (
          <div style={{ fontSize: '1rem' }}>
            <p>Please use the below link to order</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', marginRight: '8px' }}>
                {link}
              </a>
              <CopyToClipboard text={link}>
                <FaCopy style={{ cursor: 'pointer' }} />
              </CopyToClipboard>
            </div>
          </div>
        )}
        <img src={KidsSanta} style={{ width: '24rem', marginTop: '2rem' }} alt="" />
      </div>
    </>
  );
};
