import React, { useContext, useEffect, useState } from 'react';

import { Stepper, Step, StepLabel, IconButton } from '@mui/material';
import { VerifyProducts } from '../VerifyProducts';
import { FinaliseProducts } from '../FinaliseProducts';
import { ShareList } from '../ShareList';
import { ParentSuccess } from '../ParentSuccess/ParentSuccess';
import { getWishlist } from '../../../../../../api';
import { useUser } from '../../../../../../UserContext';
import LoaderContext from '../../../../../../context/LoaderContext';
import CloseIcon from '@mui/icons-material/Close';
import wishlistImage from "../../../../../../images/wishlist.png";

const steps = ['Verify Product List', 'Finalise Products', 'Share List'];

export const KidProfile = props => {
  const [activeStep, setActiveStep] = useState(0);
  const [wishlist, setWishlist] = useState([]);
  const [child, setChild] = useState();
  const { userData } = useUser();
  const [link, setLink] = useState();
  const { setIsLoading, setMessage } = useContext(LoaderContext);
  const [loading, setLoading] = useState(false);

  const [products, setProducts] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [newItems, setNewItems] = useState([]);
  const [shareViaEmail, setShareViaEmail] = useState(true);

  const [showInfoBox, setShowInfoBox] = useState(true);
  const handleInfoBoxClose = () => {
    setShowInfoBox(false);
  };

  const getStepMessage = (step) => {
    switch (step) {
      case 0:
        return (
          <>
          STEP SIX: REVIEW THE WISH LIST.  PARENTS ONLY <br/> 
          <ul>
              <li>
                This page provides an organized and convenient way to keep track of your child’s favorite items and share them with other gift givers among your family and friends.
              </li>
              <li>
                If you want to add an item to the list, simply click "Add Item" and fill out the text box.
              </li>
              <li>
                If you want to delete an item, click on the "Edit List" button to remove an item. Click "Save & Submit" button and allow the HiSanta.Christmas Platform to match your information with the retailer's website.
              </li>
            </ul>
            <i>Note: you can navigate between children by clicking on their names atop of the page</i> 
          </>
      );

      case 1:
        return (
          <>
          STEP SEVEN: VERIFY PRODUCT LIST:  PARENTS ONLY <br/> 
          <ul>
              <li>
                Congratulations!  The platoform used your wish list to map against the available items on the retailer site.  For each item, scroll through using the left & right arrows to find the item you think your child wants.
              </li>
              <li>
                Click the "Select button" and that item will be added to the list. You may select multiple items from a single line. 
              </li>
              <li>
               If you want to add another gift idea, simply add it into the text box and click the button and it will be added at the bottom. 
              </li>
              <li>
                To remove an item, just deselect product item.  When all done, click "Share Product List"
              </li>
            </ul>
          </>
      );
      
      case 2:
        return (
          <>
          STEP EIGHT: SEND YOUR LIST TO YOUR FAMILY & FRIENDS: PARENTS ONLY <br/> 
          <ul>
              <li>
                You are almost done!  Add a personalized email greeting in the text box provided.  And then, your registry is ready to be sent!
              </li>
              <li>
                You can type in the individual email addresses, separated by commas.  Or you can copy the link to the registry page and paste it in your favorite email platform like Outlook, Gmail or Yahoo.  Remember, upon clicking the registry link, your gift givers will be directed to the exact product you selected on the retailer site (Walmart.com in our beta test).
              </li>
              <li>
               The gift giver performs the purchase directly with the retailer site, just like any other online transaction.  We just made it super simple.  Ho ho ho!  Wasn't that easy? 
              </li>
            </ul> 
          </>
        );

        case 3:
        return (
          <>
          STEP NINE: Repeat the same steps for other kids, if they have completed their wishlist.<br/> 
          </>
        );
    }
  };

  const capitalize = name => {
    let finalName = '';
    name.split(' ').forEach((word, i) => {
      if (i == 0) {
        finalName += word.charAt(0).toUpperCase() + word.slice(1);
        finalName += ' ';
      } else {
        finalName += word;
        if (i !== name.split(' ').length - 1) {
          finalName += ' ';
        }
      }
    });
    return finalName;
  };

  useEffect(() => {
    setIsLoading(true);
    setLoading(true);
    setMessage(`Fetching wishlist for ${props.child.childname}...`);
    getWishlist({ userid: userData.userInfo.userid }).then(res => {
      setIsLoading(false);
      setLoading(false);
      setMessage();
      setWishlist(res.data.wishList);
    });
  }, []);

  useEffect(() => {
    const child = wishlist.find(wish => wish.childid === props.child.childid);
    setChild(child);

    if (child && Object.keys(child).length) {
      setProducts(
        child.wishlistitem.map((item, i) => {
          return {
            id: i + 1,
            name: capitalize(item),
          };
        })
      );
    } else {
      setProducts([]);
    }
  }, [wishlist, activeStep]);

  if (!loading && (!products || !products.length)) {
    // return <div className="no-wishlist-message">Wishlist is not available for {props.child.childname}! </div>

      return    <div className="fetch-data" style={{ marginTop: 0 }}>
            <img
              src={wishlistImage}
              alt="No Child Profiles Added"
              style={{ width: '40%', height: 'auto' }}
            />
          </div>      
          

  }

  return (
    <>
      {showInfoBox && (
        <div className="info-box" style={{ position: 'relative', background: 'black', padding: '20px', fontSize: '18px', color: 'white' }}>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleInfoBoxClose}
            sx={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              background: 'black',
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <span>
            {getStepMessage(activeStep)} 
          </span>
          <br />
        </div>
      )}
      <br />
      {products && products.length > 0 && (
        <div>
          {activeStep <= 2 && (
            <div className="stepper-container">
              <Stepper activeStep={activeStep} nonLinear={false}>
                {steps.map((label, index) => {
                  return (
                    <Step key={label} index={index}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </div>
          )}
          {activeStep === 0 && (
            <VerifyProducts
              child={child}
              products={products}
              setProducts={setProducts}
              setStep={setActiveStep}
              setNewItems={setNewItems}
              newItems={newItems}
            />
          )}
          {activeStep === 1 && (
            <FinaliseProducts
              products={products}
              newItems={newItems}
              setStep={setActiveStep}
              setSelectedProducts={setSelectedProducts}
            />
          )}
          {activeStep === 2 && (
            <ShareList
              child={child}
              userData={userData}
              products={selectedProducts}
              setStep={setActiveStep}
              setLink={setLink}
              shareViaEmail={shareViaEmail}
              setShareViaEmail={setShareViaEmail}
            />
          )}
          {activeStep > 2 && <ParentSuccess link={link} shareViaEmail={shareViaEmail} />}
        </div>
      )}
    </>
  );
};
