import './Item.css';

export const TemplateItem = ({ src, setStep, id, setSelectedTemplate }) => {
  return (
    <div
      className="template-item"
      onClick={() => {
        if (setSelectedTemplate) {
          setSelectedTemplate({ id, src });
          return setStep(2);
        }
      }}
    >
      <img src={src} alt="" />
    </div>
  );
};
