import React from 'react';

// Styles for the content box
const stylesContent = {
    margin: '4rem',
    marginBottom: 0
};

/**
 * ContentBox Component
 * 
 * @param {Object} props - React component props.
 * @param {ReactNode} props.children - The content to be rendered inside the ContentBox.
 * @returns {ReactNode} - Rendered component with specified styles.
 */
export const ContentBox = ({ children }) => {
    return (
        <div style={stylesContent}>{children}</div>
    );
};
