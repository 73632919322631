import './index.css';
import { TemplateContainer } from '../../../kids/components/TemplateSelector/Container';

export const TemplateGallery = () => {
    return (
        <div className="template-gallery profile">
            <TemplateContainer/>
        </div>
    );
}
