// Importing styles for the components
import './index.css';

/**
 * Button Component
 * 
 * @param {Object} props - React component props.
 * @param {string} props.variant - Variant of the button (e.g., 'fullWidth', 'secondary').
 * @param {ReactNode} props.children - Content to be displayed inside the button.
 * @param {Object} props.style - Inline styles to be applied to the button.
 * @param {Function} props.onClick - Callback function to be executed on button click.
 * @returns {ReactNode} - Rendered button component.
 */
export const Button = ({ variant, children, style, onClick, disabled }) => {
    return (
        <button
            onClick={onClick}
            className={`btn ${variant === 'fullWidth' ? 'full-width' : variant === 'secondary' ? 'secondary' : ''}`}
            style={style}
            disabled={disabled}
        >
            {children}
        </button>
    );
}

/**
 * LogoBtn Component
 * 
 * @param {Object} props - React component props.
 * @param {string} props.logo - Source URL for the logo image.
 * @param {string} props.text - Text content to be displayed alongside the logo.
 * @param {Function} props.onClick - Callback function to be executed on button click.
 * @param {Object} props.style - Inline styles to be applied to the component.
 * @returns {ReactNode} - Rendered component with logo and text.
 */
export const LogoBtn = ({ logo, text, onClick, style }) => {
    return (
        <div className='logo-btn' role="button" onClick={onClick} style={style}>
            <img src={logo} alt="" />
            {text}
        </div>
    );
}
