// Importing necessary components, styles, and utilities
import { LogoBtn } from '../Button';
import { Logo } from '../Logo';
import UserIcon from '../../images/user.svg';
import './index.css';
import { useUser } from '../../UserContext';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

/**
 * AppHeader Component
 * 
 * @param {Object} props - React component props.
 * @param {boolean} props.showParentSignIn - Flag indicating whether to show the Parent Sign In button.
 * @param {boolean} props.loggedIn - Flag indicating whether the user is logged in.
 * @returns {ReactNode} - Rendered component with app logo and optional Parent Sign In button.
 */
export const AppHeader = ({ showParentSignIn, loggedIn }) => {
  // Accessing user-related functions and data from UserContext
  const { setUserData } = useUser();

  // Accessing navigation function from react-router-dom
  const navigate = useNavigate();

  // Function to handle Parent Sign In and log out actions
  const parentSign = () => {
    // Clearing user data and removing stored information on sign out
    setUserData({});
    localStorage.removeItem('latestUserId');
    localStorage.removeItem('user');
    Cookies.remove('userId');

    // Navigating to the home page after sign out
    navigate('/');
  };

  // Rendering the app header with logo and optional Parent Sign In button
  return (
    <div className="app-header">
      {/* App logo component */}
      <Logo style={{ height: '2.8rem' }} />

      {/* Conditional rendering of Parent Sign In button based on props */}
      {showParentSignIn && !loggedIn && <LogoBtn logo={UserIcon} text="Parent Sign in" onClick={parentSign} />}
    </div>
  );
};
