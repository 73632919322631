// Importing React and useState from 'react'
import React, { useState } from 'react';

// Importing LoaderContext created earlier
import LoaderContext from './LoaderContext';

/**
 * LoaderProvider Component
 * 
 * @param {Object} props - React component props.
 * @param {ReactNode} props.children - Nested components to be wrapped by the LoaderProvider.
 * @returns {ReactNode} - Rendered component with LoaderContext.Provider.
 */
const LoaderProvider = ({ children }) => {
  // State to manage loading status and message
  const [isLoading, setIsLoading] = useState('Default Data');
  const [message, setMessage] = useState('Loading data...');

  // Providing loading state and functions through LoaderContext.Provider
  return (
    <LoaderContext.Provider value={{ isLoading, setIsLoading, message, setMessage }}>
      {children}
    </LoaderContext.Provider>
  );
};

// Exporting the LoaderProvider component
export default LoaderProvider;
