// Importing necessary React components, styles, and images
import { Grid, FormControl, InputLabel, Input, IconButton } from '@mui/material';
import { Button } from '../../../components/Button';
import { useNavigate } from 'react-router-dom';

import RegisterImage from '../../../images/register.png';
import Close from '../../../images/close.svg';
import { useState } from 'react';

import { registerUser } from '../../../api';
import { useUser } from '../../../UserContext';
import Cookies from 'js-cookie';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

/**
 * Register Component
 * 
 * @param {Object} props - React component props.
 * @param {Function} props.handleClose - Function to handle closing the Register modal.
 * @returns {ReactNode} - Rendered component for the registration feature.
 */
export const Register = ({ handleClose }) => {
  // State to manage user details and registration-related state
  const [userDetails, setUserDetails] = useState({});
  const [isPasswordMatched, setIsPasswordMatched] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { setUserData } = useUser();
  const [showPassword, setShowPassword] = useState(true);

  // Accessing navigation function from react-router-dom
  const history = useNavigate();

  // Function to handle form submission
  const handleSubmit = async e => {
    e.preventDefault();
    if (userDetails.password === userDetails.cnfPassword) {
      // Passwords matched
      setIsPasswordMatched(true);
      const userDetailsPayload = { ...userDetails };
      delete userDetailsPayload.cnfPassword;

      try {
        // Attempting to register the user
        const res = await registerUser(userDetailsPayload);

        // Storing user information in local storage, session storage, and cookies
        localStorage.setItem('latestUserId', res.data.userInfo.userid);
        sessionStorage.setItem('currentUserId', res.data.userInfo.userid);
        Cookies.set('userId', res.data.userInfo.userid, { expires: 7 });

        // Setting user data in UserContext
        setUserData({
          userInfo: { ...res.data.userInfo },
          childInfo: [],
          isAuthenticated: true,
        });

        // Removing sensitive information before storing in local storage
        delete res.data.userInfo.password;

        // Creating a user object for storage
        const userObject = {
          childInfo: [],
          isAuthenticated: true,
          userInfo: { ...res.data.userInfo },
        };

        // Storing user object in local storage
        localStorage.setItem('user', JSON.stringify(userObject));

        // Navigating to the profile page
        history('/parent/profile');
      } catch (err) {
        // Handling registration error
        setIsError(true);
        setErrorMessage('Email id already exists!');
      }
    } else {
      // Passwords did not match
      setIsPasswordMatched(false);
      setErrorMessage('Password not matched!');
    }
  };

  // Function to toggle password visibility
  const handleClickShowPassword = () => setShowPassword(show => !show);

  // Function to handle mouse down event on password visibility icon
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <div className="register">
      {/* Image section */}
      <img src={RegisterImage} alt="" />

      {/* Form section */}
      <div className="">
        <div className="forms-heading">Register</div>
        <div className="forms-subheading">Let’s get started! Create your account now</div>

        {/* Display error messages */}
        <div className="error-message">
          {!isPasswordMatched && <p>{errorMessage}</p>}
          {isPasswordMatched && isError && <p>{errorMessage}!</p>}
        </div>

        {/* Forms content */}
        <div className="forms-content" style={{ width: '70%' }}>
          {/* Close button */}
          <div className="close-btn" onClick={() => handleClose(false)}>
            <img src={Close} alt="close" />
          </div>

          {/* Registration form */}
          <form onSubmit={handleSubmit}>
            <Grid container spacing="0">
              {/* First Name input field */}
              <Grid item xs="12">
                <FormControl
                  variant="standard"
                  sx={{ width: '100%', margin: '1rem' }}
                  helperText={'Name is mandatory'}
                  required
                >
                  <InputLabel id="fname">First Name</InputLabel>
                  <Input
                    labelId="fname"
                    id="fname"
                    variant="standard"
                    value={userDetails.firstName}
                    onChange={e => setUserDetails({ ...userDetails, firstName: e.target.value })}
                  />
                </FormControl>
              </Grid>

              {/* Last Name input field */}
              <Grid item xs="12">
                <FormControl variant="standard" sx={{ width: '100%', margin: '1rem' }} required>
                  <InputLabel id="lname">Last Name</InputLabel>
                  <Input
                    labelId="lname"
                    id="lname"
                    variant="standard"
                    value={userDetails.lastName}
                    onChange={e => setUserDetails({ ...userDetails, lastName: e.target.value })}
                  />
                </FormControl>
              </Grid>

              {/* Email input field */}
              <Grid item xs="12">
                <FormControl variant="standard" sx={{ width: '100%', margin: '1rem' }} required>
                  <InputLabel id="email">Email</InputLabel>
                  <Input
                    labelId="email"
                    id="email"
                    variant="standard"
                    value={userDetails.emailId}
                    required
                    onChange={e => {
                      setUserDetails({ ...userDetails, emailId: e.target.value });
                      setIsError(false);
                    }}
                  />
                </FormControl>
              </Grid>

              {/* Password input field */}
              <Grid item xs="12">
                <FormControl variant="standard" sx={{ width: '100%', margin: '1rem' }} required>
                  <InputLabel id="password">Password</InputLabel>
                  <Input
                    type="password"
                    labelId="password"
                    id="password"
                    variant="standard"
                    value={userDetails.password}
                    onChange={e => {
                      setUserDetails({ ...userDetails, password: e.target.value });
                      setIsPasswordMatched(true);
                    }}
                  />
                </FormControl>
              </Grid>

              {/* Confirm Password input field */}
              <Grid item xs="12">
                <FormControl variant="standard" sx={{ width: '100%', margin: '1rem' }} required>
                  <InputLabel id="confirm-password">Confirm Password</InputLabel>
                  <Input
                    type={showPassword ? 'password' : 'text'}
                    labelId="confirm-password"
                    id="confirm-password"
                    variant="standard"
                    value={userDetails.cnfPassword}
                    onChange={e => {
                      setUserDetails({ ...userDetails, cnfPassword: e.target.value });
                      setIsPasswordMatched(true);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        {/* Password visibility toggle button */}
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              {/* Submit button */}
              <Button variant="fullWidth" type="submit" style={{ marginTop: '8px' }}>
                Submit
              </Button>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
};
