import React, { useContext, useEffect, useState } from 'react';
import { InitialProfile } from './InitialProfile';
import { FinalProfile } from './FinalProfile';
import { getProfile } from '../../../../api';
import { useUser } from '../../../../UserContext';
import LoaderContext from '../../../../context/LoaderContext';

export function Profile() {
  const [parentProfileDetails, setParentProfileDetails] = useState({});
  const { userData } = useUser();
  const { setIsLoading, setMessage } = useContext(LoaderContext);
  useEffect(() => {
    setIsLoading(true);
    setMessage('Fetching profile...');
    getProfile({ userid: userData.userInfo.userid }).then(res => {
      setIsLoading(false);
      setParentProfileDetails({ userInfo: res.data.userInfo, childInfo: res.data.childInfo });
    });
  }, []);

  return (
    <>
      <div>
        {parentProfileDetails && parentProfileDetails?.childInfo && parentProfileDetails.childInfo.length ? (
          <FinalProfile parentProfileDetails={parentProfileDetails} />
        ) : (
          <InitialProfile
            setParentProfileDetails={setParentProfileDetails}
            parentProfileDetails={parentProfileDetails}
          />
        )}
      </div>
    </>
  );
}
