// Importing necessary components and styles
import { Logo } from '../Logo';
import { RightNav } from './RightNav';
import './index.css';

/**
 * HomeHeader Component
 * 
 * @param {Object} props - React component props.
 * @param {boolean} props.openRegister - Flag indicating whether the register modal is open.
 * @param {Function} props.setOpenRegister - Function to set the state of openRegister.
 * @param {boolean} props.openSignIn - Flag indicating whether the sign-in modal is open.
 * @param {Function} props.setOpenSignIn - Function to set the state of openSignIn.
 * @returns {ReactNode} - Rendered component with app logo and RightNav component.
 */
export const HomeHeader = ({ openRegister, setOpenRegister, openSignIn, setOpenSignIn }) => {
  return (
    <div className="header-nav">
      {/* App logo component */}
      <Logo />

      {/* RightNav component for navigation options */}
      <RightNav
        openRegister={openRegister}
        setOpenRegister={setOpenRegister}
        openSignIn={openSignIn}
        setOpenSignIn={setOpenSignIn}
      />
    </div>
  );
};
