import { Button } from '../../../../../components/Button';
import { ProductList } from './ProductList/ProductList';
import { updateWishlist } from '../../../../../api';
import { useContext } from 'react';
import LoaderContext from '../../../../../context/LoaderContext';

export const VerifyProducts = ({ child, setStep, products, setProducts, newItems, setNewItems }) => {
  const { setIsLoading, setMessage } = useContext(LoaderContext);
  const handleSubmitList = () => {
    setIsLoading(true);
    setMessage(`Updating wishlist...`);
    updateWishlist({
      wishlistid: child.wishlistid,
      wishlistitem: [...products.map(prod => prod.name), ...newItems],
    }).then(() => {
      setIsLoading(false);
      setMessage();
      setStep(1);
    });
  };

  return (
    <>
      <ProductList
        child={child}
        products={products}
        setProducts={setProducts}
        setNewItems={setNewItems}
        newItems={newItems}
      />
      <Button
        variant="primary"
        style={{ margin: '3rem 0 1rem 0' }}
        onClick={() => {
          handleSubmitList();
        }}
      style={{ float: 'right' }} 
      >
        Next
      </Button>
    </>
  );
};
