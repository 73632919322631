// Importing necessary React components, styles, and images
import { useState } from 'react';
import { Button, LogoBtn } from '../../Button';
import { Modal, Box } from '@mui/material';
import { Register } from './Register';
import { Login } from './Login';
import './index.css';
import { ForgotPassword } from './ForgotPassword';
import UserIcon from '../../../images/user.svg';

/**
 * RightNav Component
 * 
 * @param {Object} props - React component props.
 * @param {boolean} props.openRegister - State to control the visibility of the Register modal.
 * @param {Function} props.setOpenRegister - Function to set the state of openRegister.
 * @param {boolean} props.openSignIn - State to control the visibility of the Sign In modal.
 * @param {Function} props.setOpenSignIn - Function to set the state of openSignIn.
 * @returns {ReactNode} - Rendered component with navigation options and modals for Register, Sign In, and Forgot Password.
 */
export const RightNav = ({ openRegister, setOpenRegister, openSignIn, setOpenSignIn }) => {
    // State to control the visibility of the Forgot Password modal
    const [openForgot, setOpenForgot] = useState(false);

    return (
      <div className="right-nav">
        {/* Navigation buttons */}
        <LogoBtn onClick={() => setOpenSignIn(true)} logo={UserIcon} text="Parent Sign In" />
        <Button onClick={() => setOpenRegister(true)}>Register</Button>

        {/* Modals for Register, Sign In, and Forgot Password */}
        <Modal
          open={openRegister}
          onClose={() => setOpenRegister(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            {/* Register modal component */}
            <Register handleClose={() => setOpenRegister(false)} />
          </Box>
        </Modal>
        <Modal
          open={openSignIn}
          onClose={() => setOpenSignIn(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            {/* Sign In modal component */}
            <Login
              handleClose={() => setOpenSignIn(false)}
              handleRegisterOpen={() => setOpenRegister(true)}
              handleForgot={() => setOpenForgot(true)}
            />
          </Box>
        </Modal>
        <Modal
          open={openForgot}
          onClose={() => setOpenForgot(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            {/* Forgot Password modal component */}
            <ForgotPassword handleClose={() => setOpenForgot(false)} handleSignin={() => setOpenSignIn(true)} />
          </Box>
        </Modal>
      </div>
    );
}
