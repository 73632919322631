// FullScreenLoader.js
import React, { useContext } from 'react';
import LoaderContext from '../context/LoaderContext';

/**
 * FullScreenLoader Component
 * 
 * A component that displays a full-screen loader when data is loading.
 * It utilizes the isLoading state and message from the LoaderContext.
 * 
 * @returns {JSX.Element | null} - Returns the JSX element for the loader or null if isLoading is false.
 */
const FullScreenLoader = () => {
  // Accessing isLoading and message from LoaderContext
  const { isLoading, message } = useContext(LoaderContext);

  // Do not render the loader if isLoading is false
  if (!isLoading) {
    return null;
  }

  // Render the full-screen loader
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        {/* Spinning loader animation */}
        <div
          style={{
            border: '4px solid rgba(0, 0, 0, 0.1)',
            borderTop: '4px solid #3498db',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            animation: 'spin 1s linear infinite',
          }}
        ></div>
        {/* Loading message */}
        <p>{message || 'Loading data...'}</p>
      </div>
    </div>
  );
};

export default FullScreenLoader;
