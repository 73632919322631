import './profile.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const ProfileCard = ({avatar, name, isActive, setActive, sentToSanta}) => {
    return (
        <div className={`kid-card ${isActive ? 'active' : ''}`} onClick={setActive}>
            <div style={{display:'flex', justifyContent:'flex-end'}}>
            {sentToSanta && <CheckCircleIcon color="success"/>}
            </div>
            <img src={avatar} alt="" />
            <p>{name}</p>
        </div>
    );
}