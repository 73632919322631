import { Input } from '@mui/material';
import './Product.css';

import Delete from '../../../../../../images/delete.svg';
import { useState } from 'react';

export const Product = ({index, onDelete, onEdit, mode, label, id}) => {
    const [value, setValue] = useState(label);

    return (
        <div className="product">
            <div className="sl">{index}.</div>
            {mode === 'view' && <div className="label">{label}</div>}
            {mode === 'edit' && <div className='label-input'>
                <Input variant='standard' value={value} onChange={(e) => setValue(e?.target?.value || '')} onBlur={() =>  onEdit(id, value)} key={`input-${id}`} />
            </div>}
            {mode === 'edit' && <div className="icon" onClick={() => onDelete(id)}>
                <img src={Delete} alt="" width="20px" height="20px" />
            </div>}
        </div>
    );
}
