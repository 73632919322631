// Importing necessary React components and styles
import { FormControl, InputLabel, Input, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import './LetterPreview.css';

/**
 * LetterPreview Component
 * 
 * @param {Object} props - React component props.
 * @param {Object} props.letterContent - Object containing letter content data.
 * @param {boolean} props.modal - Flag indicating whether the component is displayed as a modal.
 * @returns {ReactNode} - Rendered component for previewing a letter template.
 */
export const LetterPreview = ({ letterContent, modal }) => {
  // Extracting position information from letter content
  const { position } = letterContent;

  // Calculating letter position based on the 'position' prop
  const letterPosition =
    position === 'center' ? 'flex-end' : position === 'center' ? 'flex-start' : 'center';
    // position === 'right' ? 'flex-end' : position === 'left' ? 'flex-start' : 'center';

  return (
    <div className="letter-preview" style={{backgroundImage: `url(${letterContent.templateUrl})`, backgroundSize: 'cover'}}>
      <div style={{ display: 'flex', justifyContent: letterPosition }}>
        <div className="form">
          {/* Letter content with form inputs and text areas */}
          <div className={modal ? 'letter-page-modal' : 'letter-page'} style={{color: 'black'}}>
            <div className="first">
              My name is
              {/* Input for name */}
              <FormControl
                className="letter-input"
                variant="standard"
                sx={{ minWidth: '80px', margin: '0 1rem' }}
                disabled
              >
                <InputLabel id="name" />  
                <Input
                  labelId="name"
                  style={{ marginTop: '0' }}
                  id="name"
                  variant="standard"
                  readOnly
                  value={letterContent.name}
                />
              </FormControl>
              and I am
              {/* Input for age */}
              <FormControl
                className="letter-input"
                variant="standard"
                sx={{ maxWidth: '150px', margin: '0 1rem' }}
                disabled
              >
                <InputLabel id="name" />
                <Input
                  labelId="name"
                  id="name"
                  style={{ marginTop: '0' }}
                  variant="standard"
                  readOnly
                  value={letterContent.age}
                />
              </FormControl>
            </div>
            <div className="second">
              This year I have been
              {/* Checkbox group for behavior */}
              <FormGroup disabled>
                <FormControlLabel
                  control={<Checkbox color="secondary" name="behaviour" checked={letterContent.behaviour === 'good'} />}
                  label="Good"
                />
                <FormControlLabel
                  control={<Checkbox color="secondary" name="behaviour" checked={letterContent.behaviour === 'veryGood'} />}
                  label="Very Good"
                />
                <FormControlLabel
                  control={<Checkbox color="secondary" name="behaviour" checked={letterContent.behaviour === 'naughty'} />}
                  label="Naughty"
                />
              </FormGroup>
            </div>

            {/* Text areas for nice things, things to do next year, gratefulness, and Christmas wishlist */}
            <div className="third">
              <span>Some of the nice things I did were:</span>
              <div className="text" contentEditable={false}>
                {letterContent.niceThings}
              </div>
            </div>
            <div className="third">
              <span>Nice things I’d like to do next year:</span>
              <div className="text" contentEditable={false}>
                {letterContent.thingsNextYear}
              </div>
            </div>
            <div className="third">
              Things I am grateful for:
              <div className="text" contentEditable={false}>
                {letterContent.gratefulFor}
              </div>
            </div>
            <div className="third">
              My Christmas wishlist:
              <div className="text" contentEditable={false}>
                {letterContent.wishes}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
