import React from 'react';
import './index.css';

export const Faqs = () => {
  return (
    <div className="faqs profile">
      <div className='page-heading'>
        Frequently Asked Questions (FAQs)
      </div>

      <div className='page-subtext'>
        This page serves as a valuable resource to help users find quick solutions and gain a better understanding of our website, products, or services. 
      </div>

      <div className='faq-item'>
        <div className='page-subheading'>1. How can my child write a letter to Santa Claus on your website?</div>
        <div className='page-subtext'>To write a letter to Santa Claus, simply navigate to the &quot;Write a Letter&quot; section on our website. There, your child can access a special template where they can express their thoughts, share their experiences from the past year, and mention their Christmas wishes. It&apos;s a magical opportunity for your child to communicate directly with Santa Claus!</div>
      </div>

      <div className='faq-item'>
        <div className='page-subheading'>2. Can my child request specific gifts in their letter to Santa Claus?</div>
        <div className='page-subtext'>Absolutely! In the letter, your child can include a wish list mentioning the specific gifts they would love to receive for Christmas. Santa Claus loves hearing about children&apos;s wishes and will do his best to bring joy to their hearts. Just encourage your child to be specific and remember to keep the spirit of giving and gratitude alive!</div>
      </div>

      <div className='faq-item'>
        <div className='page-subheading'>3. How can I ensure that my child&apos;s letter reaches Santa Claus?</div>
        <div className='page-subtext'>Rest assured, we have a direct line to Santa Claus at the North Pole! Once your child has completed their letter, simply follow the instructions on our website to submit it. We&apos;ll make sure Santa receives every letter and reads them personally. However, please keep in mind that Santa Claus receives a lot of letters, so submitting it well in advance will ensure a timely response.</div>
      </div>

      <div className='faq-item'>
        <div className='page-subheading'>4. Will my child receive a response from Santa Claus?</div>
        <div className='page-subtext'>Yes! We strive to make every child&apos;s Christmas extra special. Upon submitting the letter, your child can provide their contact information, including their name and address. Santa Claus loves surprises, so if your child has been good this year, they may receive a personalized response from Santa himself! Keep an eye on your mailbox during the holiday season.</div>
      </div>

      <div className='faq-item'>
        <div className='page-subheading'>5. Can my child submit multiple letters to Santa Claus?</div>
        <div className='page-subtext'>While we understand the excitement of writing multiple letters, we encourage children to submit one letter per year to ensure that every child has an opportunity to communicate with Santa Claus. It&apos;s a wonderful chance for your child to express themselves and cherish the anticipation of Christmas.</div>
      </div>

      <div className='faq-item'>
        <div className='page-subheading'>6. What if my child&apos;s wish list changes after submitting the letter?</div>
        <div className='page-subtext'>We understand that children&apos;s preferences can change, even after sending the letter. Unfortunately, we cannot modify the submitted letters. However, children can always keep their updated wish list in mind for future discussions with their parents or for next year&apos;s letter to Santa Claus.</div>
      </div>
    </div>
  );
}
