import Check from '../../../../../../images/check.png';
import Amazon from '../../../../../../images/amazon.png';
import Target from '../../../../../../images/target.png';
import Walmart from '../../../../../../images/walmart.png';

import { useState } from 'react';

import './ProductCard.css';
import StarRatings from 'react-star-ratings';
import Tooltip from '@mui/material/Tooltip';
import { Modal, Box } from '@mui/material';
import Close from '../../../../../../images/close.svg';

const platformImages = {
  amazon: Amazon,
  walmart: Walmart,
  target: Target,
};

export const ProductCard = ({ product, variant, hadndleSelectProduct, shareList }) => {
  const [openViewDetails, setOpenViewDetails] = useState(false);

  const handleProductView = () => {
    setOpenViewDetails(true);
  };

  return (
    <div className="product-card">
      <div
        className="product-img"
        style={{ backgroundImage: `url(${product.productImage})`, backgroundSize: '7rem' }}
      />
      <div className="details">
        <Tooltip title={product.productDescription} arrow>
          <div className="description">
            {product.productDescription.slice(0, 60)}
            {'...'}
          </div>
        </Tooltip>
        <div className="details-price">
          <div className="price">{product.brandName}</div>
          <div className="platform">
            <StarRatings
              rating={product.productRating && +product.productRating}
              starRatedColor="#cc7222"
              numberOfStars={5}
              starDimension="15px"
              starSpacing="1px"
            />
          </div>
        </div>
        <div className="details-price">
          <div className="price">{product.productPrice}</div>
          {product.originalPrice !== '$undefined' && <div className="original-price">{product.originalPrice}</div>}
          <div className="platform">
            <img src={platformImages[product.seller]} alt="" />
          </div>
        </div>
        <div className="select-view-buttons">
          {variant && variant === 'registry' ? (
            <div className="unslected-product" onClick={() => window.open(product.productUrl)}>
              Order Now
            </div>
          ) : product.selected ? (
            <div className="selected-product">
              <span onClick={() => !shareList && hadndleSelectProduct(product)}>Selected</span>
            </div>
          ) : (
            <div className="unslected-product" onClick={() => hadndleSelectProduct(product)}>
              Select
            </div>
          )}
          <div className="unslected-product" onClick={() => handleProductView(product)}>
            View Details
          </div>
        </div>
      </div>
      <Modal
        open={openViewDetails}
        onClose={setOpenViewDetails}
        aria-labelledby="modal-modal-title" 
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 700,
            maxHeight: 550,
            overflowY: 'auto',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="view-product-details">
            <div className="description">{product.productDescription}</div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '2rem', marginTop: '1.5rem' }}>
              <div className='product-display'>
                <img src={product.productImage} />
              </div>
              <div className="product-features">
                <div className="view-details-price">
                  <div className="product-name">{product.brandName}</div>
                  <div className="product-stars">
                    <span style={{fontSize: '1.3rem'}}>{product.productRating}</span>
                    <StarRatings
                      rating={product.productRating && +product.productRating}
                      starRatedColor="#cc7222"
                      numberOfStars={5}
                      starDimension="25px"
                      starSpacing="4px"
                    />
                  </div>
                  <div className="product-price">{product.productPrice}</div>
                  <div className="product-platform">
                    <img src={platformImages[product.seller]} alt="" />
                  </div>
                  <div className="">
                    {product.selected ? (
                      <div className="selected-product-view-details">
                        <img src={Check} alt="" />
                        <span onClick={() => !shareList && hadndleSelectProduct(product)}>Selected</span>
                      </div>
                    ) : (
                      <div className="unslected-product-view-setails" onClick={() => hadndleSelectProduct(product)}>
                        Select
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="close-btn" onClick={() => setOpenViewDetails(false)}>
              <img src={Close} alt="close" />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
