
import React, { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('user')));
  const userIdFromCookie = Cookies.get('userId');
  const userId = userIdFromCookie || null;
  return <UserContext.Provider value={{ userData, setUserData, userId }}>{children}</UserContext.Provider>;
}

export function useUser() {
  return useContext(UserContext);
}
