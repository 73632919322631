import { ContentBox } from '../../components/ContentBox';
import { HomeHeader } from '../../components/HomeHeader';
import './index.css';
import { BigText, MediumText, SmallText } from '../../components/Text';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { useContext, useState } from 'react';
import LoaderContext from '../../context/LoaderContext';

const HeroBg = require('../../images/hero-bg.png');
const Content1 = require('../../images/content1.png');
const Works1 = require('../../images/Works1.png');
const Works2 = require('../../images/Works2.png');
const Works3 = require('../../images/Works3.png');
const Arrow = require('../../images/arrow-right.png');
const Content3 = require('../../images/Content3.png');
const Content4 = require('../../images/Content4.png');
const Footer = require('../../images/Footer.png');

export const HomePage = () => {
  const [openRegister, setOpenRegister] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);
  const currentUserId = sessionStorage.getItem('currentUserId');
  const {setIsLoading} = useContext(LoaderContext); 
  
  setIsLoading(false);
  const handleLetsGetStarted = () => {
    if (currentUserId) {
      setOpenSignIn(true);
    } else {
      setOpenRegister(true);
    }
  };
  return (
    <div className="home">
      <div
        className="hero"
        style={{
          backgroundImage: `url(${HeroBg})`,
        }}
      >
        <HomeHeader
          openRegister={openRegister}
          setOpenRegister={setOpenRegister}
          openSignIn={openSignIn}
          setOpenSignIn={setOpenSignIn}
        />
        <div className="hero-text">
          <div>
            <p className="small">Unlock The</p>
            <p className="big">Magic of Christmas</p>
            <p className="normal">
              Send your email to Santa and make this <br /> Christmas extra special!
            </p>
          </div>
        </div>
      </div>
      <ContentBox>
        <div className="content-1" style={{ paddingBottom: '4rem', borderBottom: '1px solid #D9D9D9' }}>
          <div className="content-1-text">
            <BigText style={{ fontSize: '2.5rem' }}>
              Christmas is a time of wonder,
              <br /> joy, and heartfelt connections
            </BigText>
            <MediumText style={{ fontSize: '1.5rem' }}>
              And what better way to celebrate the spirit of the season than by reaching out to Santa Claus himself.
            </MediumText>
            <SmallText>
              Writing a <span style={{ fontWeight: 'bold' }}>letter to Santa</span> is a cherished tradition that holds
              the power to bring happiness and excitement to the holiday season. And now, you can send an email message
              directly to Santa’s Toy Shop!
            </SmallText>
            <div>
              <Button onClick={handleLetsGetStarted}>Let`s get started</Button>
            </div>
          </div>
          <img src={Content1} alt="" />
        </div>
      </ContentBox>
      <ContentBox>
        <div className="content-2" style={{ paddingBottom: '4rem', borderBottom: '1px solid #D9D9D9' }}>
          <div className="content-2-text">
            <BigText style={{ fontSize: '2.5rem' }}>How it works</BigText>
            <MediumText style={{ fontSize: '1.5rem' }}>Writing a letter to Santa is easy and fun. Simply follow these steps:</MediumText>
            <div className="content-2-cards">
              <div className="cards-item">
                <img src={Works1} alt="" />
                <SmallText>Start by choosing your favorite template or crafting your own unique letter.</SmallText>
              </div>
              <img src={Arrow} alt="" />
              <div className="cards-item">
                <img src={Works2} alt="" />
                <SmallText>
                  Type a letter to Santa, and let him know about your wishes, dreams, and acts of kindness.
                </SmallText>
              </div>
              <img src={Arrow} alt="" />
              <div className="cards-item">
                <img src={Works3} alt="" />
                <SmallText>Submit your letter to Santa`s, and let the magic unfold!</SmallText>
              </div>
            </div>
            <div>
              <Button onClick={handleLetsGetStarted}>Start writing</Button>
            </div>
          </div>
        </div>
      </ContentBox>
      <ContentBox>
        <div className="content-3" style={{ paddingBottom: '4rem', borderBottom: '1px solid #D9D9D9' }}>
          <img src={Content3} alt="" />
          <div className="content-3-text">
            <BigText style={{ fontSize: '2.5rem' }}>Santa’s promise</BigText>
            <MediumText style={{ fontSize: '1.5rem' }}>Santa himself reads every single email that arrives at the North Pole.</MediumText>
            <SmallText>
              While Santa can`t grant every wish, he treasures the joy and sincerity behind each letter. Remember, the
              true magic of Christmas lies in the act of giving, kindness, and love.
            </SmallText>
          </div>
        </div>
      </ContentBox>
      <ContentBox>
        <div className="content-1" style={{ marginBottom: '-4rem' }}>
          <div className="content-1-text">
            <BigText style={{ fontSize: '2.5rem' }}>Start your Christmas adventure</BigText>
            <MediumText style={{ fontSize: '1.5rem' }}>
              Ready to make memories that will last a lifetime? Begin your Christmas adventure now by writing your email
              to Santa Claus!
            </MediumText>
            <SmallText style={{ marginBottom: '20px' }}>
              Click the <b>&quot;Start Writing&quot;</b> button below to access our magical letter templates and bring
              your wishes to life.
            </SmallText>
            <SmallText>
              Spread the joy, embrace the magic, and let Santa`s be your gateway to a holiday season filled with love,
              laughter, and enchantment!
            </SmallText>
            <div>
              <Button onClick={handleLetsGetStarted}>Let`s get started</Button>
            </div>
          </div>
          <img src={Content4} alt="" />
        </div>
      </ContentBox>
      <div className="footer">
        <img src={Footer} alt="" />
        <div className="footer-text">
          © 2023 All rights reserved.
          <Link to="/privacy-policy" style={{ marginLeft: '4px' }}>
            Privacy Policy
          </Link>
          <span>|</span>
          <Link to="/terms-and-conditions">Terms of Service</Link>
        </div>
      </div>
    </div>
  );
};
