import { ProductCard } from '../ProductCard/ProductCard';
import './CarouselContainer.css';

export const CarouselContainer = ({ products, hadndleSelectProduct }) => {
  return (
    <div className="carousel-container">
      {products.map((product, index) => {
        return (
          <ProductCard
            key={index}
            product={product}
            hadndleSelectProduct={hadndleSelectProduct}
          />
        );
      })}
    </div>
  );
};
