import { Sidebar } from "./components/Sidebar";
import {
    Outlet
} from "react-router-dom";
import './index.css';

export const Parent = () => {
    return (
        <>
            <div className="parent-app">
                <Sidebar />
                <div className="content">
                    <Outlet />
                </div>
            </div>
        </>
    );
}