import { Button } from '../../../../../components/Button';
import '../index.css';
import { Grid, MenuItem, Select, InputLabel, FormControl, Input } from '@mui/material';
import { useUser } from '../../../../../UserContext';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

// Import necessary components from Material-UI
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import { createProfile } from '../../../../../api';
import { getProfile } from '../../../../../api';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const InitialProfile = props => {
  const { userData } = useUser();
  const [kidsDetails, setKidsDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const history = useNavigate();

  const [profileDetails, setProfileDetails] = useState();


  const [showInfoBox, setShowInfoBox] = useState(true);
  const handleInfoBoxClose = () => {
    setShowInfoBox(false);
  };

  useEffect(() => {
    if (!props.parentProfileDetails.userInfo) {
      return;
    }
    setProfileDetails({
      firstName: props.parentProfileDetails.userInfo.firstname,
      lastName: props.parentProfileDetails.userInfo.lastname,
      emailId: props.parentProfileDetails.userInfo.emailid,
      password: props.parentProfileDetails.userInfo.password,
      cnfPassword: props.parentProfileDetails.userInfo.password,
    });
  }, [props.parentProfileDetails.userInfo]);

  const [childDetails, setChildDetails] = useState({
    name: '',
    gender: '',
    age: '',
    shoeSize: '',
    topSize: '',
    bottomSize: '',
  });

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setChildDetails({
      ...childDetails,
      [name]: value,
    });
  };

  const addKidDetails = e => {
    e.preventDefault();

    // Clear input fields
    if (childDetails.name.length !== 0) {
      setKidsDetails([...kidsDetails, childDetails]);
    }

    setChildDetails({
      name: '',
      gender: '',
      age: '',
      shoeSize: '',
      topSize: '',
      bottomSize: '',
    });
  };

  const handleDelete = index => {
    setKidsDetails(kidsDetails.filter((a, i) => i !== index));
  };


  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // Function to handle opening the dialog
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

    // Function to close the dialog
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

    const handleConfirmAction = () => {
       history('/kid');
       handleCloseDialog();
  };

  const createPayload = (profileDetails, kidsDetails) => {
    return {
      userId: userData.userInfo.userid,
      userInfo: {
        firstName: profileDetails.firstName,
        lastName: profileDetails.lastName,
        password:
          props.parentProfileDetails.userInfo.password === profileDetails.password ? '' : profileDetails.password,
      },
      childInfo: kidsDetails.map(kid => {
        return {
          childname: kid.name,
          gender: kid.gender,
          childage: kid.age,
          topsize: kid.topSize,
          bottomsize: kid.bottomSize,
          shoesize: kid.shoeSize,
        };
      }),
    };
  };


  const TwoButtonDialog = ({ open, handleClose, handleConfirm, title, content, confirmLabel, cancelLabel }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {cancelLabel}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

  const handleProfileDetails = () => {
    const payload = createPayload(profileDetails, kidsDetails);
    if (profileDetails.password !== profileDetails.cnfPassword) {
      setOpen(true);
    } else {
      createProfile(payload).then(() => {
        getProfile({ userid: userData.userInfo.userid }).then(res1 => {
          props.setParentProfileDetails({ userInfo: res1.data.userInfo, childInfo: res1.data.childInfo });
          // history('/kid');
          handleOpenDialog();
        });
      });
    }
  };

  const handleClose = () => {
    // Hide the Snackbar when the user closes it
    setOpen(false);
  };

  return (
    <>
      {profileDetails && profileDetails.firstName && <div className="profile">
      <TwoButtonDialog
        open={isDialogOpen}
        handleClose={handleCloseDialog}
        handleConfirm={handleConfirmAction}
        title="Profile saved successfully!"
        content="STEP 2 : Select 'Launch Child Profile' if you want to compose a letter with configured profiles"
        confirmLabel="Launch Child Profile"
        cancelLabel="Cancel"
      />

       {showInfoBox && (
      <div className="info-box" style={{ position: 'relative', background: 'black', padding: '20px', fontSize: '18px', color: 'white' }}>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleInfoBoxClose}
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            background: 'black',
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <span>
          <b>[STEP-1] PARENTS</b> <br/> - Enter your child’s profile information, so Santa can get the right age-appropriate toys or the right size of clothing for your child. 
          <br />
            - Enter each child’s profile details and  Press the “Add Child” button.
          <br/>
            - When finished adding all of your children, click “Save Profile” to move on to the next step.
        </span>
        <br/>
      </div>
    )}
    <br/>

        <div className="page-heading">Profile Information</div>

        {/* Profile Details */}
        <div className="page-subheading">Personal Details</div>
        <div className="profile-form">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl
                sx={{ m: 1, width: "25ch" }}
                variant="standard"
                fullWidth
              >
                <InputLabel htmlFor="fname">First Name</InputLabel>
                <Input
                  labelId="fname"
                  id="fname"
                  type="text"
                  variant="standard"
                  value={profileDetails.firstName}
                  onChange={(e) =>
                    setProfileDetails({
                      ...profileDetails,
                      firstName: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel htmlFor="lname">Last Name</InputLabel>
                <Input
                  labelId="lname"
                  id="lname"
                  variant="standard"
                  value={profileDetails.lastName}
                  onChange={(e) =>
                    setProfileDetails({
                      ...profileDetails,
                      lastName: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  labelId="email"
                  id="email"
                  variant="standard"
                  value={profileDetails.emailId}
                  required
                  disabled
                  onChange={(e) =>
                    setProfileDetails({
                      ...profileDetails,
                      emailId: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>

        {/* Change Password */}
        <div className="page-section-separator" />
        <div className="page-subheading">Change Password</div>
        <div className="profile-form">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  type="password"
                  labelId="password"
                  id="password"
                  variant="standard"
                  value={profileDetails.password}
                  onChange={(e) =>
                    setProfileDetails({
                      ...profileDetails,
                      password: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel htmlFor="confirm-password">
                  Confirm Password
                </InputLabel>
                <Input
                  type={showPassword ? "password" : "text"}
                  labelId="confirmPassword"
                  id="confirmPassword"
                  variant="standard"
                  value={profileDetails.cnfPassword}
                  onChange={(e) =>
                    setProfileDetails({
                      ...profileDetails,
                      cnfPassword: e.target.value,
                    })
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>

        {/* New Child details */}
        <div className="page-section-separator" />
        <div className="page-subheading">Child`s Details</div>
        <div className="profile-form" style={{ marginBottom: "2rem" }}>
          <form onSubmit={addKidDetails}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Name</InputLabel>
                  <Input
                    name="name"
                    value={childDetails.name}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    label="Gender"
                    name="gender"
                    value={childDetails.gender}
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="boy">Boy</MenuItem>
                    <MenuItem value="girl">Girl</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Age</InputLabel>
                  <Select
                    label="Age"
                    name="age"
                    value={childDetails.age}
                    onChange={handleChange}
                    required
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
                      (val, i) => {
                        return (
                          <MenuItem value={val} key={i}>
                            {val}
                          </MenuItem>
                        );
                      }
                    )}
                    {/* Add more age options */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Shoe Size</InputLabel>
                  <Select
                    label="Shoe Size"
                    name="shoeSize"
                    value={childDetails.shoeSize}
                    onChange={handleChange}
                    required
                  >
                    {[4, 5, 6, 7, 8, 9, 10,11,12,1,2,3].map((val, i) => {
                      return (
                        <MenuItem value={val} key={i}>
                          {val}
                        </MenuItem>
                      );
                    })}
                    {/* Add more shoe size options */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Top Size</InputLabel>
                  <Select
                    label="Top Size"
                    name="topSize"
                    value={childDetails.topSize}
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="12-18M (Infant)">12-18M (Infant)</MenuItem>
                    <MenuItem value="18-24M (Infant)">18-24M (Infant)</MenuItem>
                    <MenuItem value="2T (Toddler)">2T (Toddler)</MenuItem>
                    <MenuItem value="3T (Toddler)">3T (Toddler)</MenuItem>
                    <MenuItem value="4T (Toddler)">4T (Toddler)</MenuItem>
                    <MenuItem value="5T (Toddler)">5T (Toddler)</MenuItem>
                    <MenuItem value="4/5 (XS) Child">4/5 (XS) Child</MenuItem>
                    <MenuItem value="6/7 (S) Child">6/7 (S) Child</MenuItem>
                    <MenuItem value="8 (M) Child">8 (M) Child</MenuItem>
                    <MenuItem value="10/12 (L) Child">10/12 (L) Child</MenuItem>
                    {/* Add more top size options */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Bottom Size</InputLabel>
                  <Select
                    label="Bottom Size"
                    name="bottomSize"
                    value={childDetails.bottomSize}
                    onChange={handleChange}
                    required
                  >
                      
                    <MenuItem value="12-18M (Infant)">12-18M (Infant)</MenuItem>
                    <MenuItem value="18-24M (Infant)">18-24M (Infant)</MenuItem>
                    <MenuItem value="2T (Toddler)">2T (Toddler)</MenuItem>
                    <MenuItem value="3T (Toddler)">3T (Toddler)</MenuItem>
                    <MenuItem value="4T (Toddler)">4T (Toddler)</MenuItem>
                    <MenuItem value="5T (Toddler)">5T (Toddler)</MenuItem>
                    <MenuItem value="4/5 (XS) Child">4/5 (XS) Child</MenuItem>
                    <MenuItem value="6/7 (S) Child">6/7 (S) Child</MenuItem>
                    <MenuItem value="8 (M) Child">8 (M) Child</MenuItem>
                    <MenuItem value="10/12 (L) Child">10/12 (L) Child</MenuItem>

                    {/* Add more bottom size options */}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Button type="submit" variant="contained" color="primary">
              Add Child
            </Button>
          </form>
        </div>

        {!!kidsDetails.length && (
          <TableContainer component={Paper} sx={{ marginBottom: "2rem" }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell align="right">Gender</StyledTableCell>
                  <StyledTableCell align="right">Age</StyledTableCell>
                  <StyledTableCell align="right">Shoe size</StyledTableCell>
                  <StyledTableCell align="right">Top Size</StyledTableCell>
                  <StyledTableCell align="right">Bottom Size</StyledTableCell>
                  <StyledTableCell align="right">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {kidsDetails.map((row, i) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.gender}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.age}</StyledTableCell>
                    <StyledTableCell align="right">
                      {row.shoeSize}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.topSize}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.bottomSize}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton
                        color="primary"
                        onClick={() => handleDelete(i)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Snackbar
          open={open}
          autoHideDuration={4000} // Automatically hide after 2 seconds
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity="success"
          >
            Passwords not matched!
          </Alert>
        </Snackbar>

        <Button variant="contained" color="primary" onClick={handleProfileDetails} style={{ float: 'left' }}>
              Save Profile
        </Button>

      </div>}
    </>
  );
};
