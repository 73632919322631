import React, { useCallback } from 'react';
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';

import './KidSuccess.css';
import check from '../../../../images/check.png';
import KidsSanta from '../../../../images/KidsSanta.png';

import { useUser } from '../../../../UserContext';
import Cookies from 'js-cookie';

const totalDuration = 10,
  emittersLifeCount = totalDuration / 0.5;

const configs = {
  // ... (unchanged particle configurations)
};

/**
 * Component displaying success message after sending a letter.
 * @param {Object} props - Component props
 * @param {function} props.setStep - Function to set the active step
 * @param {Object} props.selectedProfile - Selected child's profile
 * @returns {JSX.Element} - Rendered KidSuccess component
 */
export const KidSuccess = ({ setStep, selectedProfile }) => {
  const { setUserData } = useUser();
  const { pathname } = useLocation();

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    console.log({ container });
  }, []);

  const handleLogout = () => {
    setUserData({});
    localStorage.removeItem('latestUserId');
    localStorage.removeItem('user');
    Cookies.remove('userId');
  };

  return (
    <>
      <Particles id="tsparticles" options={configs} init={particlesInit} loaded={particlesLoaded} />
      <div className="kids-success">
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setStep(0)}
          sx={{
            position: 'absolute',
            right: '20px',
            top: '15px',
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <img src={check} alt="" />
        <p>
          Congratulations {selectedProfile.name}, <br />
          your letter has been delivered to Santa Claus!
        </p>
        <img src={KidsSanta} style={{ width: '20rem', marginTop: '0rem' }} alt="" />
        <div className="template-buttons" style={{ marginBottom: '2.5rem', marginTop: '0.5rem' }}>
          <Button
            variant="contained"
            onClick={() => setStep(0)}
            style={{
              backgroundColor: '#015523',
              color: 'white',
              borderRadius: '20px',
              textTransform: 'capitalize',
              fontSize: '1.0rem',
              marginRight: '10px',
            }}
          >
            Send Another Letter
          </Button>

          {pathname === '/kid' && ( // Conditionally render the logout button only for /kid route
            <Button
              variant="contained"
              onClick={handleLogout}
              style={{
                backgroundColor: '#ba1509',
                color: 'white',
                borderRadius: '20px',
                textTransform: 'capitalize',
                fontSize: '1.0rem',
              }}
            >
              Logout
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
