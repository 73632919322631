// Importing necessary components from 'react-router-dom'
import { Outlet, Navigate } from 'react-router-dom';

/**
 * ProtectedRoute Component
 * 
 * @param {boolean} isAllowed - Flag indicating whether the user is allowed to access the route.
 * @param {string} redirectPath - Path to redirect to if user is not allowed (default: '/landing').
 * @param {ReactNode} children - Nested components to be rendered within the ProtectedRoute.
 * @returns {ReactNode} - Rendered component or a navigation redirect.
 */

export const ProtectedRoute = ({
  isAllowed,
  redirectPath = '/landing',
  children,
}) => {
  // If the user is not allowed, redirect to the specified path
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  // Render children if provided, otherwise render the nested Outlet
  return children ? children : <Outlet />;
};
