import React, { useState, useEffect, useRef } from 'react';
import { Button } from '../../../../../components/Button';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

// Import necessary components from Material-UI
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Select,
  Snackbar,
  Alert,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import '../index.css';
import EditIcon from '@mui/icons-material/Edit';
import { updateProfile } from '../../../../../api';
import { useUser } from '../../../../../UserContext';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export function FinalProfile({ parentProfileDetails }) {
  const { userData } = useUser();
  const [isProfileEditing, setIsProfileEditing] = useState(false);
  const [isChildEditing, setIsChildEditing] = useState(false);
  const [editingChild, setEditingChild] = useState({});
  const [isPasswordEditing, setIsPasswordEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [kidsDetails, setKidsDetails] = useState([]);
  const [childDeleted, setChildDeleted] = useState([]);
  const history = useNavigate();


  const [showInfoBox, setShowInfoBox] = useState(true);
  const handleInfoBoxClose = () => {
    setShowInfoBox(false);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // Function to handle opening the dialog
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

    // Function to close the dialog
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };


    const handleConfirmAction = () => {
       history('/kid');
       handleCloseDialog();
  };


  const [userDetails, setUserDetails] = useState({
    firstName: parentProfileDetails.userInfo.firstname,
    lastName: parentProfileDetails.userInfo.lastname,
    emailId: parentProfileDetails.userInfo.emailid,
    password: parentProfileDetails.userInfo.password || '',
    cnfPassword: parentProfileDetails.userInfo.password || '',
    userId: userData.userInfo.userid,
  });

  const [childDetails, setChildDetails] = useState(
    parentProfileDetails.childInfo.map(child => {
      return {
        childId: child.childid,
        gender: child.gender,
        childname: child.childname,
        childage: child.childage,
        topsize: child.topsize,
        bottomsize: child.bottomsize, 
        shoesize: child.shoesize,
      };
    })
  );

  const [newChildDetails, setNewChildDetails] = useState({
    name: '',
    gender: '',
    age: '',
    shoeSize: '',
    topSize: '',
    bottomSize: '',
  });

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleProfileEdit = () => {
    setIsProfileEditing(true);
  };

  const formRef = useRef(null);

  const handlePasswordEdit = () => {
    setIsPasswordEditing(true);
  };

  const handleChildEdit = id => {
    const child = childDetails.find(child => {
      return child.childId === id;
    });
    setEditingChild(child);
    setIsChildEditing(true);
  };

  const handleChildDelete = id => {
    setChildDetails(prevChildDetails => {
      return prevChildDetails.filter(child => {
        if (child.childId === id) {
          setChildDeleted(prev => [...prev, child.childId]);
          setAlertMessage(`${child.childname} removed successfully`);
          setOpen(true);
        }
        return child.childId !== id;
      });
    });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleChildInputChange = e => {
    const { name, value } = e.target;
    const updatedChildInfo = childDetails.map(child => {
      if (child.childId === editingChild.childId) {
        setEditingChild({ ...child, [name]: value });
        return { ...child, [name]: value };
      }
      return child;
    });

    setChildDetails(updatedChildInfo);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setNewChildDetails({
      ...newChildDetails,
      [name]: value,
    });
  };

  const addKidDetails = e => {
    e.preventDefault();

    // Clear input fields
    if (newChildDetails.name.length !== 0) {
      setKidsDetails([...kidsDetails, newChildDetails]);
    }

    setNewChildDetails({
      name: '',
      gender: '',
      age: '',
      shoeSize: '',
      topSize: '',
      bottomSize: '',
    });
  };

  const handleDelete = index => {
    setKidsDetails(kidsDetails.filter((a, i) => i !== index));
  };

  const handleUpdateDetails = () => {
    const password = parentProfileDetails.userInfo.password === userDetails.password ? '' : userDetails.password;
    delete userDetails.emailId;
    const newChildInfo = kidsDetails.map(kid => {
      return {
        childname: kid.name,
        gender: kid.gender,
        childage: kid.age,
        topsize: kid.topSize,
        bottomsize: kid.bottomSize,
        shoesize: kid.shoeSize,
      };
    });
    const payload = {
      userInfo: { ...userDetails, password },
      childInfo: childDetails,
      childAdded: newChildInfo,
      childDeleted,
    };

    if (userDetails.password !== userDetails.cnfPassword) {
      setAlertMessage('Password Not Matched');
      setOpen(true);
    } else {
        updateProfile(payload).then(() => {
        setAlertMessage('Profile Information Saved Successfully!');
        // setOpen(true);
        handleOpenDialog();
      });
    }
  };

  const handleRedirectToKidsPersona = async () => {
    await handleUpdateDetails(); 
    history('/kid');
  };



  const handleClose = () => {
    setOpen(false);
  };

  const TwoButtonDialog = ({ open, handleClose, handleConfirm, title, content, confirmLabel, cancelLabel }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {cancelLabel}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

  const handleClickOutside = event => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setIsProfileEditing(false);
      setIsPasswordEditing(false);
      setIsChildEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="profile">
        <TwoButtonDialog
        open={isDialogOpen}
        handleClose={handleCloseDialog}
        handleConfirm={handleConfirmAction}
        title="Profile updated successfully!"
        content="STEP 2 : Select 'Launch Child Profile' if you want to compose a letter with configured profiles"
        confirmLabel="Launch Child Profile"
        cancelLabel="Cancel"
      />
   {showInfoBox && (
      <div className="info-box" style={{ position: 'relative', background: 'black', padding: '20px', fontSize: '18px', color: 'white' }}>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleInfoBoxClose}
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            background: 'black',
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <span>
          <b>[STEP-1] PARENTS</b> <br/> - Enter your child’s profile information, so Santa can get the right age-appropriate toys or the right size of clothing for your child. 
          <br />
            - Enter each child’s profile details and  Press the “Add Child” button.
          <br/>
            - When finished adding all of your children, click “Save Profile” to move on to the next step.
        </span>
        <br/>
      </div>
    )}
    <br/>
      <div className="page-heading">Profile Information</div>
      <div className="page-subheading-view">Personal Details</div>
      <div className="profile-form">
        {isProfileEditing ? (
          <div ref={formRef}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="firstName">First Name</InputLabel>
                  <Input
                    id="firstName"
                    name="firstName"
                    variant="standard"
                    value={userDetails.firstName}
                    onChange={handleInputChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="lastName">Last Name</InputLabel>
                  <Input
                    id="lastName"
                    name="lastName"
                    variant="standard"
                    value={userDetails.lastName}
                    onChange={handleInputChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <Input
                    id="email"
                    name="email"
                    variant="standard"
                    value={userDetails.emailId}
                    onChange={handleInputChange}
                    disabled
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '100vw' }}>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    First Name: <p className="value">{userDetails.firstName}</p>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    Last Name: <p className="value">{userDetails.lastName}</p>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    Email: <p className="value">{userDetails.emailId}</p>
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <EditIcon sx={{ cursor: 'pointer' }} onClick={handleProfileEdit} />
          </div>
        )}
      </div>

      <div className="page-section-separator" />
      <div className="page-subheading-view">Change Password</div>
      <div className="profile-form" style={{ marginBottom: '2rem' }}>
        {isPasswordEditing ? (
          <div ref={formRef}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    variant="standard"
                    value={userDetails.password}
                    onChange={handleInputChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="cnfPassword">Confirm Password</InputLabel>
                  <Input
                    id="cnfPassword"
                    name="cnfPassword"
                    type={showPassword ? 'text' : 'password'}
                    variant="standard"
                    value={userDetails.cnfPassword}
                    onChange={handleInputChange}
                    required
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Save
                </Button>
              </Grid> */}
            </Grid>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div style={{ width: '100vw' }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    Password:{' '}
                    <p className="value" type="password">
                      {Array.from(userDetails.password).map((char, i) => {
                        return <span key={i}>*</span>;
                      })}
                    </p>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    Confirm Password:{' '}
                    <p className="value" type="password">
                      {Array.from(userDetails.cnfPassword).map((char, i) => {
                        return <span key={i}>*</span>;
                      })}
                    </p>
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <EditIcon sx={{ cursor: 'pointer' }} onClick={handlePasswordEdit} />
          </div>
        )}
      </div>

      <div className="page-section-separator" />
      <div className="page-subheading-view">Child`s Details</div>
      <div className="profile-form" style={{ marginBottom: '2rem' }}>
        <div>
          {childDetails.map((child, index) => {
            return (
              <>
                {isChildEditing && child.childId === editingChild.childId ? (
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel>Name</InputLabel>
                          <Input
                            name="childname"
                            value={editingChild.childname}
                            onChange={handleChildInputChange}
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel>Gender</InputLabel>
                          <Select
                            label="Gender"
                            name="gender"
                            value={editingChild.gender}
                            onChange={handleChildInputChange}
                            required
                          >
                            <MenuItem value="boy">Boy</MenuItem>
                            <MenuItem value="girl">Girl</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel>Age</InputLabel>
                          <Select
                            label="Age"
                            name="childage"
                            value={editingChild.childage}
                            onChange={handleChildInputChange}
                            required
                          >
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15].map((val, i) => {
                              return (
                                <MenuItem value={val} key={i}>
                                  {val}
                                </MenuItem>
                              );
                            })}
                            {/* Add more age options */}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel>Shoe Size</InputLabel>
                          <Select
                            label="Shoe Size"
                            name="shoeSize"
                            value={editingChild.shoesize}
                            onChange={handleChildInputChange}
                            required
                          >
                            {[4, 5, 6, 7, 8, 9, 10].map((val, i) => {
                              return (
                                <MenuItem value={val} key={i}>
                                  {val}
                                </MenuItem>
                              );
                            })}
                            {/* Add more shoe size options */}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel>Top Size</InputLabel>
                          <Select
                            label="Top Size"
                            name="topsize"
                            value={editingChild.topsize}
                            onChange={handleChildInputChange}
                            required
                          >
                            <MenuItem value="small">Small</MenuItem>
                            <MenuItem value="medium">Medium</MenuItem>
                            <MenuItem value="large">Large</MenuItem>
                            {/* Add more top size options */}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel>Bottom Size</InputLabel>
                          <Select
                            label="Bottom Size"
                            name="bottomsize"
                            value={editingChild.bottomsize}
                            onChange={handleChildInputChange}
                            required
                          >
                            <MenuItem value="small">Small</MenuItem>
                            <MenuItem value="medium">Medium</MenuItem>
                            <MenuItem value="large">Large</MenuItem>
                            {/* Add more bottom size options */}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {/* <Button variant="contained" color="primary" type="submit">
                      Save Kid
                    </Button> */}
                    <div className="page-section-separator" />
                  </div>
                ) : (
                  <div>
                    <div key={child.childname} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ width: '100vw' }}>
                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Name: <p className="value">{child.childname}</p>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Gender: <p className="value">{child.gender}</p>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Age: <p className="value">{child.childage}</p>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Shoe Size: <p className="value">{child.shoesize}</p>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Top Size: <p className="value">{child.topsize}</p>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Botton Size: <p className="value">{child.bottomsize}</p>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                      {/* <Button variant="contained" color="primary" onClick={() => handleChildEdit(child.childname)}>
                    Edit
                  </Button> */}
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                        <EditIcon sx={{ cursor: 'pointer' }} onClick={() => handleChildEdit(child.childId)} />
                        <DeleteIcon sx={{ cursor: 'pointer' }} onClick={() => handleChildDelete(child.childId)} />
                      </div>
                    </div>
                    {index !== childDetails.length - 1 && <div className="page-section-separator" />}
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>

      <div className="page-section-separator" />
      <div className="profile-form" style={{ marginBottom: '2rem' }}>
        <form onSubmit={addKidDetails}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel>Name</InputLabel>
                <Input name="name" value={newChildDetails.name} onChange={handleChange} required />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select label="Gender" name="gender" value={newChildDetails.gender} onChange={handleChange} required>
                  <MenuItem value="boy">Boy</MenuItem>
                  <MenuItem value="girl">Girl</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel>Age</InputLabel>
                <Select label="Age" name="age" value={newChildDetails.age} onChange={handleChange} required>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((val, i) => {
                    return (
                      <MenuItem value={val} key={i}>
                        {val}
                      </MenuItem>
                    );
                  })}
                  {/* Add more age options */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel>Shoe Size</InputLabel>
                <Select
                  label="Shoe Size"
                  name="shoeSize"
                  value={newChildDetails.shoeSize}
                  onChange={handleChange}
                  required
                >
                    {[4, 5, 6, 7, 8, 9, 10,11,12,1,2,3].map((val, i) => {
                    return (
                      <MenuItem value={val} key={i}>
                        {val}
                      </MenuItem>
                    );
                  })}
                  {/* Add more shoe size options */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel>Top Size</InputLabel>
                <Select
                  label="Top Size"
                  name="topSize"
                  value={newChildDetails.topSize}
                  onChange={handleChange}
                  required
                >     
                    <MenuItem value="12-18M (Infant)">12-18M (Infant)</MenuItem>
                    <MenuItem value="18-24M (Infant)">18-24M (Infant)</MenuItem>
                    <MenuItem value="2T (Toddler)">2T (Toddler)</MenuItem>
                    <MenuItem value="3T (Toddler)">3T (Toddler)</MenuItem>
                    <MenuItem value="4T (Toddler)">4T (Toddler)</MenuItem>
                    <MenuItem value="5T (Toddler)">5T (Toddler)</MenuItem>
                    <MenuItem value="4/5 (XS) Child">4/5 (XS) Child</MenuItem>
                    <MenuItem value="6/7 (S) Child">6/7 (S) Child</MenuItem>
                    <MenuItem value="8 (M) Child">8 (M) Child</MenuItem>
                    <MenuItem value="10/12 (L) Child">10/12 (L) Child</MenuItem>

                  {/* Add more top size options */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel>Bottom Size</InputLabel>
                <Select
                  label="Bottom Size"
                  name="bottomSize"
                  value={newChildDetails.bottomSize}
                  onChange={handleChange}
                  required
                >
                     
                    <MenuItem value="12-18M (Infant)">12-18M (Infant)</MenuItem>
                    <MenuItem value="18-24M (Infant)">18-24M (Infant)</MenuItem>
                    <MenuItem value="2T (Toddler)">2T (Toddler)</MenuItem>
                    <MenuItem value="3T (Toddler)">3T (Toddler)</MenuItem>
                    <MenuItem value="4T (Toddler)">4T (Toddler)</MenuItem>
                    <MenuItem value="5T (Toddler)">5T (Toddler)</MenuItem>
                    <MenuItem value="4/5 (XS) Child">4/5 (XS) Child</MenuItem>
                    <MenuItem value="6/7 (S) Child">6/7 (S) Child</MenuItem>
                    <MenuItem value="8 (M) Child">8 (M) Child</MenuItem>
                    <MenuItem value="10/12 (L) Child">10/12 (L) Child</MenuItem>


                  {/* Add more bottom size options */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" color="primary">
            Add Child
          </Button>
        </form>
      </div>

      {!!kidsDetails.length && (
        <TableContainer component={Paper} sx={{ marginBottom: '2rem' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="right">Gender</StyledTableCell>
                <StyledTableCell align="right">Age</StyledTableCell>
                <StyledTableCell align="right">Shoe size</StyledTableCell>
                <StyledTableCell align="right">Top Size</StyledTableCell>
                <StyledTableCell align="right">Bottom Size</StyledTableCell>
                <StyledTableCell align="right">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {kidsDetails.map((row, i) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.gender}</StyledTableCell>
                  <StyledTableCell align="right">{row.age}</StyledTableCell>
                  <StyledTableCell align="right">{row.shoeSize}</StyledTableCell>
                  <StyledTableCell align="right">{row.topSize}</StyledTableCell>
                  <StyledTableCell align="right">{row.bottomSize}</StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton color="primary" onClick={() => handleDelete(i)}>
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

  <Button variant="contained" color="primary" onClick={handleUpdateDetails} style={{ float: 'left' }}>
        Save Profile
      </Button>

  {/* <Button variant="contained" color="primary" onClick={handleRedirectToKidsPersona} style={{ float: 'right' }}>
        Next: Launch Letter Writing
      </Button> */}
    <br/><br/>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert elevation={6} variant="filled" onClose={handleClose} severity="success">
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
