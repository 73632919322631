// Importing Axios library for making HTTP requests
import axios from 'axios';

// Creating an instance of Axios with a base URL and default headers
const instance = axios.create({
  baseURL: 'https://hisanta.christmas/rest/api',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Exporting functions for interacting with the API

// Register a new user
export const registerUser = data => {
  return instance.post(`/registerUser`, data);
};

// Login an existing user
export const loginUser = data => {
    return instance.post(`/loginUser`, data);
};

// Create a user profile
export const createProfile = data => {
  return instance.post(`/createProfile`, data);
}

// Update an existing user profile
export const updateProfile = data => {
  return instance.post(`/updateProfile`, data);
}

// Get user profile information
export const getProfile = data => {
  return instance.post(`/getProfile`, data);
}

// Get information about a child
export const getChildInfo = data => {
  return instance.post(`/getChildInfo`, data);
}

// Get letter templates
export const getLetterTemplate = () => {
  return instance.get(`/getletterTemplate`);
}

// Request password reset
export const forgotPassword = (data) => {
  return instance.post(`/forgotPassword`, data);
}

// Save wishlist items
export const saveWishList = (data) => {
  return instance.post(`/saveWishList`, data);
}

// Get user's wishlist
export const getWishlist = (data) => {
  return instance.post(`/getWishlist`, data);
}

// Get a list of products
export const getProductList = (data) => {
  return instance.post(`/productList`, data);
}

// Update wishlist items
export const updateWishlist = (data) => {
  return instance.post(`/updateWishlist`, data);
}

// Create a new order
export const createOrder = (data) => {
  return instance.post(`/createOrder`, data);
}

// Get order information by ID
export const getOrder = (data) => {
  return instance.post(`/fetchOrderbyId`, data);
}
