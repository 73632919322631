// Logo.js
import logo from '../images/Logo.svg';

/**
 * Logo Component
 * 
 * A reusable component for displaying the application logo.
 * 
 * @param {Object} style - Optional styling for the logo (e.g., height, width).
 * @returns {JSX.Element} - Returns the JSX element for the logo.
 */
export const Logo = ({ style }) => {
  return (
    <img
      src={logo}
      alt="logo"
      style={{
        height: '3.5rem', // Default height
        ...style, // Apply additional styles passed as props
      }}
    />
  );
};
