import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../../../../components/Button';
import { TemplateItem } from './Item';
import './Container.css';
import { getLetterTemplate } from '../../../../api';
import LoaderContext from '../../../../context/LoaderContext';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

export const TemplateContainer = ({ setStep, setSelectedTemplate }) => {
  const [templates, setTemplates] = useState([]);
  const { setIsLoading, setMessage } = useContext(LoaderContext);

  useEffect(() => {
    setIsLoading(true);
    setMessage('Fetching templates...');
    getLetterTemplate().then(res => {
      setIsLoading(false);
      setMessage();
      setTemplates(res.data.templateUrl);
    });
  }, []);

  const isParentTemplatesRoute = window.location.pathname === '/parent/templates';

  const [showInfoBox, setShowInfoBox] = useState(isParentTemplatesRoute);

  const handleInfoBoxClose = () => {
    setShowInfoBox(false);
  };

  return (
    <div className="template-container">
      {showInfoBox && (
        <div className="info-box" style={{ position: 'relative', background: 'black', padding: '20px', fontSize: '18px', color: 'white' }}>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleInfoBoxClose}
            sx={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              background: 'black',
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <span>
            <b>Admin</b> <br /> - Following are the available templates for letter writing
          </span>
          <br />
        </div>
      )}
      <br />

      <div style={{ marginBottom: "3rem", width: "100%" }}>
        <p className="page-heading" style={{ fontSize: "", color: "#D70F0F" }}>
          Select a Template
        </p>
        <p className="page-subtext" style={{ fontWeight: "400" }}>
          Choose from a delightful variety of Christmas-themed templates.
          Whether you're a parent or a child, you'll find the perfect design to
          write a heartfelt letter to Santa Claus.
          <br/>
          <span>
            Please Note: Click "Next" if you dont want to apply any template
          </span>
        </p>
      </div>
      <div
        className="template-holder"
        style={{ borderBottom: "1px solid #D9D9D9" }}
      >
        {templates && templates.length > 0 && templates.map((temp, i) => {
          return (
            <TemplateItem
              src={temp}
              id={i + 1}
              setStep={setStep}
              key={temp}
              setSelectedTemplate={setSelectedTemplate}
            />
          );
        })}
      </div>
      {setStep && (
        <div className="template-buttons">
          <Button onClick={() => setStep(0)}>Previous</Button>
          <Button onClick={() => setStep(2)}>Next</Button>
        </div>
      )}
    </div>
  );
};
